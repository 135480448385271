import React, { useEffect, useState } from "react";
import Header from "./Header";
import Layout from "./Layout";
import Emote from "./Emote.model";
import StreamerHeader from "./StreamerHeader";
import Tiers from "./Tiers";
import Tier from "./Tier";
import EasterEgg from "./EasterEgg";
import TierPayment from "./TierPayment";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import config from "./Config";
import filterPrice from "./helpers/filterPrice";
import { GiftPage } from "./GiftPage";
import axios from "axios";

interface Streamer {
  channel: {
    broadcaster_id: string;
    broadcaster_language: string;
    broadcaster_login: string;
    broadcaster_name: string;
    delay: number;
    game_id: string;
    game_name: string;
    tags: [];
    title: string;
  };
  user: {
    broadcaster_type: "partner" | "affiliate" | "normal";
    created_at: string;
    description: string;
    display_name: string;
    id: string;
    login: string;
    offline_image_url: string;
    profile_image_url: string;
    type: string;
    view_count: number;
  };
}

export default ({ parentOToken, ...props }: { parentOToken: string }) => {
  let match = useRouteMatch();
  let { streamer: streamerURI } = useParams();
  const [prices, setPrices] = useState<Array<any>>([]);
  const [streamerId, setStreamerId] = useState<number | undefined | null>();
  const [streamerObj, setStreamerObj] = useState<Streamer | undefined>();
  const [emoteObj, setEmoteObj] = useState<any>();

  const getPrices = async (): Promise<any> => {
    return fetch(
      `${config.apiUrl}/subscriptions/active?streamerName=${streamerURI}`,
      {
        headers: {
          "Client-ID": config.clientId,
        },
      }
    );
  };

  useEffect(() => {
    (async () => {
      const req = await getPrices();
      const { data: apiPrice } = await req.json();
      setPrices(apiPrice);
    })();
  }, []);

  useEffect((): void => {
    (async () => {
      if (streamerURI) {
        if (!parentOToken) return;
        const {
          data: { data },
        } = await axios.get(`${config.apiUrl}/twitch/streamer/${streamerURI}`);
        if (!data) {
          return;
        }
        setStreamerObj(data);
      }
    })();
  }, [streamerURI]);

  useEffect(() => {
    (async () => {
      try {
        if (typeof streamerObj === "undefined") {
          return;
        }
        if (typeof streamerObj.user?.id === "undefined") {
          return;
        }
        const req = await fetch(
          `${config.apiUrl}/twitch/chat/emotes/${streamerObj?.user?.id}`
        );
        const { data } = await req.json();
        setEmoteObj(data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [streamerObj]);

  const pathname = window.location.pathname;

  const type = streamerObj?.user?.broadcaster_type;
  const name = streamerObj?.user?.display_name;

  return (
    <>
      <EasterEgg />
      <Header
        title="سابسکرایب آنی توییچ"
        description="بدون نیاز به ارسال پسوورد توییچ، به صورت قانونی و آنی استریمر مورد علاقه خود را سابسکرایب کنید، برای شروع Tier مورد نظر را انتخاب کنید."
      />
      <Layout className="mt-55 mb-50">
        {streamerObj && (
          <StreamerHeader
            className="mb-40"
            title={streamerObj?.user?.display_name}
            followers={streamerObj?.user?.view_count}
            logo={streamerObj?.user?.profile_image_url}
            type={streamerObj?.user?.broadcaster_type}
            banner={streamerObj?.user?.offline_image_url}
          />
        )}
        <Switch>
          {pathname !== "/" && /\/$/.test(pathname) && (
            <Redirect to={pathname.slice(0, -1)} />
          )}
          <Route path={`${match.path}/:tier/gift/:quantity`}>
            {streamerObj && (
              <TierPayment
                streamer={streamerObj?.user?.display_name}
                price={prices}
                oToken={parentOToken}
                giftMode
              />
            )}
          </Route>
          <Route path={`${match.path}/:tier/gift`}>
            {streamerObj && (
              <GiftPage
                streamer={streamerObj?.user?.display_name}
                price={prices}
              />
            )}
          </Route>
          <Route path={`${match.path}/:tier`}>
            {streamerObj && (
              <TierPayment
                streamer={streamerObj?.user?.display_name}
                price={prices}
                oToken={parentOToken}
              />
            )}
          </Route>
          <Route path={match.path}>
            {console.log(type)}
            <Tiers type={type && type === undefined ? "normal" : type}>
              {filterPrice(1, prices) && (
                <Tier
                  title="Tier 1"
                  benefits={[
                    "مشاهده استریم بدون تبلیغات",
                    `حمایت مستقیم از ${name || ""}`,
                    "دریافت Emote های اختصاصی Tier 1",
                    "دریافت یا آپگرید Tier 1 Badge",
                  ]}
                  emotes={
                    emoteObj &&
                    emoteObj?.filter((emote: any) => emote?.tier == 1000)
                  }
                  price={prices && filterPrice(1, prices)?.price}
                  isCommunityGiftActive={
                    prices && filterPrice(1, prices)?.isCommunityGiftActive
                  }
                  communityGiftRange={
                    prices && filterPrice(1, prices)?.communityGiftRange
                  }
                />
              )}
              {filterPrice(2, prices) && (
                <Tier
                  title="Tier 2"
                  benefits={[
                    "تمامی امکانات Tier 1",
                    "دریافت Emote های اختصاصی Tier 2",
                    "دریافت یا آپگرید Tier 2 Badge",
                  ]}
                  emotes={
                    emoteObj &&
                    emoteObj?.filter((emote: any) => emote?.tier == 2000)
                  }
                  price={prices && filterPrice(2, prices)?.price}
                  isCommunityGiftActive={
                    prices && filterPrice(2, prices)?.isCommunityGiftActive
                  }
                  communityGiftRange={
                    prices && filterPrice(2, prices)?.communityGiftRange
                  }
                />
              )}
              {filterPrice(3, prices) && (
                <Tier
                  title="Tier 3"
                  benefits={[
                    "تمامی امکانات Tier 1 و Tier 2",
                    "دریافت Emote های اختصاصی Tier 3",
                    "دریافت یا آپگرید Tier 3 Badge",
                  ]}
                  emotes={
                    emoteObj &&
                    emoteObj?.filter((emote: any) => emote?.tier == 3000)
                  }
                  price={prices && filterPrice(3, prices)?.price}
                  isCommunityGiftActive={
                    prices && filterPrice(3, prices)?.isCommunityGiftActive
                  }
                  communityGiftRange={
                    prices && filterPrice(3, prices)?.communityGiftRange
                  }
                />
              )}
            </Tiers>
          </Route>
        </Switch>
      </Layout>
    </>
  );
};
