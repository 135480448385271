import React from "react";
import IBuyer from "./Buyer.model";

interface SuggestionDropdownProps {
  searchResults: Array<object>;
  handleSearchResult: (result: any) => void;
  buyer: IBuyer;
}

export default ({
  searchResults,
  handleSearchResult,
  buyer,
}: SuggestionDropdownProps) => {
  if (buyer?.name && searchResults?.length > 0 && !buyer?.confirmed) {
    return (
      <div
        className={`search-suggestion-dropdown position-absolute py-20 px-20 rounded-bottom`}
      >
        <div className="row">
          <small className="d-flex pb-20 text-muted">انتخاب کنید:</small>
          {searchResults &&
            searchResults.length > 0 &&
            searchResults.map((result: any, i: number) => {
              if (i < 4) {
                return (
                  <div
                    className={`col-12 ${
                      searchResults.length === i + 1 ? "" : "mb-15"
                    }`}
                    key={i}
                  >
                    <div
                      className="search-result d-flex align-items-center rounded  py-15 px-20"
                      onClick={() => handleSearchResult(result)}
                    >
                      <img
                        height={40}
                        width={40}
                        className="rounded-circle ml-10"
                        src={
                          result?.user?.logo ||
                          result?.user?.thumbnail_url ||
                          result?.user?.profile_image_url
                        }
                      />
                      {result?.user?.display_name}
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </div>
    );
  }
  return <></>;
};
