import filterPrice from "./helpers/filterPrice";
import React from "react";

export const CheckoutDetails = ({tier, price, streamer, quantity}: {tier: number; price: any; streamer: string; quantity: number;}) => {
    return (
        <div className="app-tier-checkout px-25 py-25">
            <div className="app-tier-checkout-row">
                <b>مبلغ قابل پرداخت:</b> {price && (filterPrice(tier, price)?.price * (quantity || 1))?.toLocaleString()}
            </div>
            <div className="app-tier-checkout-row">
                <b>سابسکریپشن برای کانال:</b> {streamer}
            </div>
            <div className="app-tier-checkout-row">
                <b>نوع سابسکریپشن:</b> {quantity && 'گیفت'} Tier {tier}
            </div>
            {
                quantity &&
                <div className="app-tier-checkout-row">
                    <b>تعداد:</b> {quantity}
                </div>
            }
        </div>
    )
}