import Header from "./Header";
import React, { useState } from "react";
import Layout from "./Layout";

export default () => {
  const [lang, setLang] = useState("fa");

  const handleChangeLang = () => {
    if (lang === "fa") {
      setLang("en");
    } else if (lang === "en") {
      setLang("fa");
    } else {
      setLang("fa");
    }
  };
  return (
    <div>
      <Header title={"حریم خصوصی"} description="Privacy Policy" />
      <Layout className="mt-55">
        <div className="px-40 px-lg-60 pt-40">
          <a
            className="d-block bg-light rounded p-3 text-center mb-4 font-weight-bold"
            onClick={handleChangeLang}
          >
            {lang === "fa" ? "View in English" : "دیدن به فارسی"}
          </a>
          {lang === "fa" && (
            <>
              <h5 className="font-weight-bold ">سابسکریپشن</h5>
              <p>
                در هیچ یک از مراحل سابسکریپشن، اعم از وب، اکستنشن و موبایل،
                هیچگونه اطلاعات هویتی و خصوصی مانند پسوورد و یا توکن به عنوان
                ورودی از یوزر دریافت نشده و کپچر نمی شود.
              </p>
              <p>
                هیچگونه اطلاعات کاربری اعم از تاریخچه صفحات دیده شده و اطلاعات
                خصوصی از طریق اکستنشن سابسکرایب دونو دریافت و یا در سرور ها
                ذخیره نمی شود.
              </p>
              <h5 className="font-weight-bold ">پرداخت</h5>
              <p>
                اطلاعات پرداختی کاربران به هیچ وجه توسط دونو و یا اکستنشن دونو
                ذخیره نمی شود. بدیهی است دونو مسئولیتی در قبال اطلاعات ذخیره شده
                توسط ارائه دهنده های پرداختی (مثل زرین پال) ندارد.
              </p>
              <h5 className="font-weight-bold ">استریمر</h5>
              <p>
                پنل استریمر صرفا در جهت سهولت مشاهده اطلاعات سابسکرایب ها توسط
                استریمر تعبیه شده و نحوه استفاده استریمر از آن برای دونو مشهود
                نیست.
              </p>
              <p>
                برای ورود به پنل توییچ از ساز و کار توییچ استفاده می شود و
                همانطور که در صفحه اهراز توییچ آورده شده تنها از ایمیل کاربر
                برای ثبت نام استفاده می شود.
              </p>
              <p>
                اطلاعات سابسکریپشن استریمر ها به صورت محرمانه بوده و تحت هیچ
                شرایطی در دست سرویس های دیگر قرار نمی گیرد.
              </p>
              <h5 className="font-weight-bold ">پشتیبانی</h5>
              <p>
                جهت هرگونه سوال و یا پشتیبانی لطفا با ایمیل help@dono.gg در
                ارتباط باشید.
              </p>
            </>
          )}
          {lang === "en" && (
            <div className="text-left" style={{ direction: "ltr" }}>
              <h5 className="font-weight-bold ">Subscription</h5>
              <p>
                In none of the subscription steps, including web, extension and
                mobile, any identity and private information such as password or
                token as input from the user is not received and captured.{" "}
              </p>
              <p>
                No user information, including history of pages viewed and
                private information, is received or stored on servers through
                the Dono subscription extension.
              </p>
              <h5 className="font-weight-bold ">Payment</h5>
              <p>
                User payment information is not stored in any way by Dono or
                Dono extensions. Obviously, Dono is not responsible for the
                information stored by payment providers (such as ZarrinPal).{" "}
              </p>
              <h5 className="font-weight-bold ">Streamers</h5>
              <p>
                The streamer panel is made solely for the convenience of viewing
                subscriber information.
              </p>
              <p>
                The Twitch mechanism is used to enter the Twitch panel, and as
                shown on the Twitch page, only the user's email is used for
                registration.
              </p>
              <p>
                Stream subscriber information is confidential and will not be
                shared with other services under any circumstances.
              </p>
              <h5 className="font-weight-bold ">Support</h5>
              <p>For any questions or support, please contact help@dono.gg. </p>
            </div>
          )}
        </div>
      </Layout>
    </div>
  );
};
