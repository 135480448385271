import Header from "./Header";
import Layout from "./Layout";
import React, { useEffect, useState } from "react";
import config from "./Config";
import { message, Table, Tooltip } from "antd";
import { useHelperContext } from "./use-helper-context";
import Indicator from "./Indicator";
import moment from "moment";
import TimeAgo from "timeago-react";
import * as timeago from "timeago.js";
import fa from "timeago.js/lib/lang/fa";
timeago.register("fa", fa);

export const Panel = () => {
  const { streamerSelf, streamerCheckDone, streamerToken } = useHelperContext();
  const [pagination, setPagination] = useState<{
    current: number;
    total: number;
  }>();
  const [subscribers, setSubscribers] = useState();
  const [intervalId, setIntervalId] = useState();

  const model = [
    {
      title: "شناسه خرید",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "گیفت",
      dataIndex: "isCommunityGift",
      key: "isCommunityGift",
      render: (isCommunityGift: boolean, row: any) =>
        isCommunityGift ? `${row?.communityGiftQuantity} گیفت` : "-",
    },
    {
      title: "خریدار",
      dataIndex: "targetName",
      key: "targetName",
      render: (targetName: string, row: any) => (
        <div className="d-flex align-items-center">
          {row?.isCommunityGift && (
            <div
              style={{ height: 30, width: 30, top: "-2px" }}
              className="overflow-hidden d-flex align-items-center justify-content-center position-relative"
            >
              <img src={require("./images/gift.gif")} width={50} />
            </div>
          )}
          <span className="inline-flex font-weight-bold text-capitalize text-dark-purple">
            {targetName}
          </span>
        </div>
      ),
    },
    {
      title: "Tier",
      dataIndex: "tier",
      key: "tier",
    },
    {
      title: "متن",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "تاریخ",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: Date) => (
        <Tooltip
          title={moment(createdAt).format("L - LT")}
          className="cursor-pointer"
        >
          <TimeAgo
            datetime={createdAt}
            locale="fa"
            className="dono-dock-time px-1 mb-0"
          />
        </Tooltip>
      ),
      width: "20%",
    },
  ];

  const refresh = async () => {
    try {
      const req = await fetch(
        `${config.apiUrl}/streamers/subscribers?page=${
          pagination?.current || 1
        }&limit=10`,
        {
          headers: {
            Authorization: `Bearer ${streamerToken}`,
          },
        }
      );
      const { data, pagination: resPagination } = await req.json();
      if (!data) {
        return;
      }
      setSubscribers(data);
      if (pagination?.current !== +resPagination?.current) {
        setPagination({
          current: +resPagination.current,
          total: +resPagination?.total * 10,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      if (!streamerToken) {
        return;
      }

      if (intervalId) {
        clearInterval(intervalId);
      }

      refresh();

      const interval = setInterval(() => refresh(), 10000);
      setIntervalId(interval);
    })();
  }, [streamerToken, pagination]);

  const handlePagination = (pageNumber: number) => {
    if (setPagination) {
      setPagination((prevState: any) => {
        if (typeof prevState !== undefined) {
          return {
            ...prevState,
            current: pageNumber,
          };
        }
      });
    }
  };

  const handleTwitchLoginInit = async () => {
    try {
      const req = await fetch(
        `${config.apiUrl}/auth/twitch/login?callbackUrl=https://sub.dono.gg/login/callback`
      );
      const { data } = await req.json();
      if (!data) {
        return message.error("مشکلی پیش آمد.");
      }
      const { url } = data;
      if (!url) {
        return;
      }

      // redirect to twitch
      window.location.href = url;
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Header
        title={streamerSelf ? streamerSelf?.twitch?.username : "پنل استریمر ها"}
        description="از طریق این پنل میتوانید سابسکریپیشن ها و سابسکریپشن های هدیه که در شبکه دونو برای کانال شما انجام شده را مشاهده کنید."
      />
      {!streamerCheckDone ? (
        <Layout className="mt-55">
          <div className="px-20 px-lg-40 pt-40 text-center">
            <Indicator />
          </div>
        </Layout>
      ) : (
        <>
          {streamerSelf ? (
            <Layout className="mt-55">
              <div className="px-20 px-lg-40 pt-40 mb-20">
                <h2 className="mb-30">آخرین سابسکریپشن ها</h2>
                <div
                  className="alert alert-info no-image d-flex align-items-center justify-content-center"
                  style={{ fontSize: "14px" }}
                >
                  این صفحه به صورت اتوماتیک ریفرش می شود
                </div>

                <Table
                  dataSource={subscribers}
                  columns={model}
                  pagination={{ ...pagination, onChange: handlePagination }}
                />
              </div>
            </Layout>
          ) : (
            <div className="row justify-content-center overflow-hidden mw-100">
              <div className="col-6">
                <Layout className="mt-55">
                  <div className="px-40 px-lg-60 pt-40 text-center">
                    <p>
                      برای ورود به این بخش کافیست با اکانت توییچ خود وارد شوید.
                    </p>
                    <div
                      className="btn btn-purple flex align-items-center"
                      onClick={handleTwitchLoginInit}
                    >
                      <img
                        className="ml-10"
                        src={require("./images/twitch.svg")}
                      />
                      ورود با توییچ
                    </div>
                  </div>
                </Layout>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
