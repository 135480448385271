import React, { useEffect, useState } from "react";
import config from "./Config";
import { message } from "antd";

const HelperContext = React.createContext<any>("");
const useProvideHelper = () => {
  const [smartBar, setSmartBar] = useState();
  const [streamerToken, setStreamerToken] = useState();
  const [streamerSelf, setStreamerSelf] = useState();
  const [streamerSubscribers, setStreamerSubscribers] = useState();
  const [streamerCheckDone, setStreamerCheckDone] = useState();

  const saveStreamerToken = (token: string) => {
    localStorage.setItem("streamerToken", token);
    setStreamerToken(token);
  };

  const deleteStreamerToken = (token: string) => {
    localStorage.removeItem("streamerToken");
    setStreamerToken(null);
    setStreamerSelf(null);
  };

  useEffect(() => {
    (async () => {
      try {
        // check if user already has a token
        const token = localStorage.getItem("streamerToken");
        if (!token) {
          return setStreamerCheckDone(true);
        }

        setStreamerToken(token);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!streamerToken) {
          return;
        }
        const req = await fetch(`${config.apiUrl}/streamers/self`, {
          headers: {
            Authorization: `Bearer ${streamerToken}`,
          },
        });
        const { data } = await req.json();
        if (!data) {
          // token is no longer valid and has to get deleted
          localStorage.removeItem("streamerToken");
          setStreamerToken("");
          return;
        }
        setStreamerCheckDone(true);
        setStreamerSelf(data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [streamerToken]);

  useEffect(() => {
    (async () => {
      try {
        const req = await fetch(`${config.apiUrl}/smartbars/latest`);
        const { data } = await req.json();
        if (!data) {
          return;
        }
        setSmartBar(data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  return {
    smartBar,
    streamerToken,
    streamerSelf,
    saveStreamerToken,
    streamerCheckDone,
    setStreamerCheckDone,
    deleteStreamerToken,
  };
};
export const useHelperContext = () => {
  return React.useContext(HelperContext);
};

export function ProvideHelperContext({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const smartBar = useProvideHelper();
  return (
    <HelperContext.Provider value={smartBar}>{children}</HelperContext.Provider>
  );
}
