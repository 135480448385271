import React from "react";
import Indicator from "./Indicator";

interface TiersProps {
    children: React.ReactNode,
    type: string | undefined | null
}

export default (props: TiersProps) => {
    const { children, type } = props;
    return (
        <div className="app-tiers px-40 px-lg-60">
            {
                (type === 'partner' || type === 'affiliate') &&
                <>
                    {children}
                </>
            }
            {
                type === undefined &&
                <Indicator />
            }
            {
                type === '' &&
                <div className="alert alert-danger">
                    این کاربر Partner یا Affiliate نبوده و به همین علت قابلیت سابسکرایب ندارد.
                </div>
            }
        </div>
    )
};
