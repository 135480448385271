import React, {ChangeEvent, useState} from 'react';
import {Link} from 'react-router-relative-link-5';

const giftsPreset = [
    {
        quantity: 1,
        image: 'https://static-cdn.jtvnw.net/subs-image-assets/1-gift-4x.png'
    },
    {
        quantity: 5,
        image: 'https://static-cdn.jtvnw.net/subs-image-assets/5-gifts-4x.png'
    },
    {
        quantity: 10,
        image: 'https://static-cdn.jtvnw.net/subs-image-assets/10-gifts-4x.png'
    },
    {
        quantity: 20,
        image: 'https://static-cdn.jtvnw.net/subs-image-assets/20-gifts-4x.png'
    },
    {
        quantity: 50,
        image: 'https://static-cdn.jtvnw.net/subs-image-assets/50-gifts-4x.png'
    },
    {
        quantity: 100,
        image: 'https://static-cdn.jtvnw.net/subs-image-assets/100-gifts-4x.png'
    },
]

export const GiftButton = ({quantity, price, full} : {quantity: number; price: string; full?: boolean;}) => <Link to={`${quantity}`} className="text-decoration-none">
    <button className={`btn btn-purple ${full ? 'w-100 px-15 py-10 rounded-top-0' : 'pl-0 pr-15 py-0'} d-flex align-items-center justify-content-between position-relative`} style={{fontSize: '14px', height: full ? undefined : '44px'}}>
        <span style={{fontSize: '13px'}}>{quantity} گیفت</span>
        <b className={`${full ? 'rounded' : 'rounded-left'} d-flex align-items-center mr-15`} style={{padding: '4px 12px 3px 12px', backgroundColor: '#623bb0', height: !full ? '44px' : undefined, fontSize: '14px'}}>{price} تومان</b>
    </button>
</Link>

export const GiftPage = ({streamer, price}: {streamer: string, price: { tier: number; price: number; }[]}) => {
    const [basePrice] = price.filter(p => p.tier === 1);
    const [value, setValue] = useState<number>(10);

    const handleCustomForm = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = +e.target.value;
        if (inputValue > 100) {
            return setValue(100);
        }
        setValue(inputValue);
    }

    return (
        <div className="app-tiers px-40 px-lg-60">
            <div className="app-tier position-relative" id="tier-1-gift">
                <Link to={`/${streamer?.toLowerCase()}`} className="app-tier-back text-decoration-none">
                    <img src={require('./images/right-arrow.svg')} className="ml-10" />
                    بازگشت
                </Link>
        <div className="app-tier-payment-form pt-20">
            <h2>هدیه سابسکریپشن <span className="d-inline-flex align-items-center justify-content-center px-15 mr-1 mb-10">Tier 1</span></h2>

            <label className="d-flex mb-30">
                یکی از پلن های هدیه را انتخاب کنید،
                هدیه به صورت اتفاقی از بین یوزر های کامیونیتی توسط توییچ اهدا خواهد شد.
            </label>
            <div className="row mx-auto" style={{maxWidth: 750}}>
                {
                    giftsPreset?.map(giftPlan => (
                        <div className="col-12 col-lg-4 mb-20">
                            <div className="rounded-top px-20 py-10 bg-light">
                                <img src={giftPlan?.image} className="mw-100" />
                            </div>
                            <GiftButton full quantity={giftPlan?.quantity} price={basePrice && (basePrice?.price * giftPlan?.quantity)?.toLocaleString()} />
                        </div>
                    ))
                }
                <div className="col-12 mt-30 border-top pt-15 border-light">
                    <div className="gift-custom-form">
                        <span className="font-weight-bold mb-15 d-flex" style={{fontSize: '15px'}}>مقدار دلخواه</span>
                        <div className="row">
                            <div className="col-auto">
                                <div className="d-flex align-items-center">
                                    <input type="number" onChange={handleCustomForm} className="form-control inline-flex ml-10 text-center" style={{height: 30, width: 50}} placeholder="تعداد" value={value} />
                                    <label>حداکثر: ۱۰۰</label>
                                </div>
                            </div>
                            <div className="col-auto position-relative" style={{top: -3}}>
                                <GiftButton quantity={value} price={basePrice && (value * basePrice?.price).toLocaleString()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            </div>
        </div>
    )
}