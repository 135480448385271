import React, {useState, useEffect} from "react";
import Emote from "./Emote.model";
import { Tooltip } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';

interface TierProps {
    title: string;
    benefits: string[];
    emotes: Emote[]
    price: any[];
    isCommunityGiftActive?: boolean;
    communityGiftRange: {
        min: number;
        max: number;
    }
}

export default ({title, benefits, emotes, price, isCommunityGiftActive, communityGiftRange}: TierProps) => {
    const [computedPrice, setComputedPrice] = useState<number | undefined>();
    const tierId = title && title.replace(' ', '-').toLowerCase();
    let match = useRouteMatch();

    return (
        <div className="app-tier" id={tierId}>
            <div className="row">
                <div className="col">
                    <h2>سابسکریپشن <span className="d-inline-flex align-items-center justify-content-center px-15 mr-1">{title}</span></h2>
                    <ul className="list-unstyled pr-0 mt-15">
                        {
                            benefits.map((benefit: string, i: number) => <li key={i}>
                                    {benefit}
                                </li>
                            )
                        }
                    </ul>
                </div>
                <div className="col-auto">
                    {
                        emotes?.length > 0 &&
                        <div className="app-tier-emotes mb-20">
                            <small className="text-muted font-weight-bold d-flex pb-2">Emote ها{tierId !== 'tier-1' ? ' (علاوه بر Tier قبلی)' : ''}:</small>
                            <div className="app-tier-emotes-list px-10 py-10 d-flex" style={{maxWidth: emotes.length > 30 ? '400px': '220px'}}>
                                <div className="row gx-0">
                                    {
                                        emotes && emotes.map((emoticon: Emote, i: number) => {
                                            if (emoticon) {
                                                return <div key={emoticon?.id} className={`col-12 ${emotes.length > 30 ? 'col-md-1' : 'col-md-3'}`}>
                                                    <div className="app-tier-emote text-center" key={i}>
                                                        <Tooltip title={emoticon?.code}>
                                                            <img src={emoticon?.images?.url_2x} width={28} height={28} className="mx-1" />
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="app-tier-purchase py-25 px-25 mt-10" id={tierId}>
                <div className="row align-items-center">
                    <div className="col">
                        <span>مبلغ قابل پرداخت:</span>
                        <span className="pr-1">{price && price.toLocaleString() + ' تومان '}</span>
                    </div>
                    <div className="col-auto">
                        <Link to={`${match.url}/${tierId}`} className="btn btn-purple px-20">
                            خرید ساب {title}
                        </Link>
                        {
                            isCommunityGiftActive &&
                            <Tooltip title="هدیه به کامیونیتی">
                                <Link to={`${match.url}/${tierId}/gift`} className="btn btn-secondary mr-10 text-black-50 border-0 px-20 border-light-gray" style={{backgroundImage: 'linear-gradient( 124deg , #e7e7f5 0%, #ddd7f3 100%)'}}>
                                    هدیه
                                </Link>
                            </Tooltip>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};
