import React, {StyleHTMLAttributes} from "react";

interface LayoutProps {
    children: React.ReactNode,
    className?: string,
    style?: any
}

export default ({ children, className, ...props }: LayoutProps) => {
    return (
        <div className={`container ${className}`} {...props}>
            <div className="row justify-content-center">
                <div className="col-lg-10">
                    <div className="app-main app-block pb-40 mx-20 mx-lg-65 mb-50">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
};
