import React from "react";

interface QAProps {
    last?: boolean;
    question: string;
    answer: React.ReactNode;
}

export default ({last, question, answer}: QAProps) => {
    return <div className={last ? "qa-body" : "qa-body mb-20"}>
        <div className="qa-question mb-half">
            {question}
        </div>
        <div className="qa-answer">
            {answer}
        </div>
    </div>
}