import React from "react";
const EasterEgg = require("react-easter");

export default () => {
  return (
    <EasterEgg
      keys={[
        "a",
        "d",
        "m",
        "i",
        "r",
        "a",
        "l",
        "enter",
        "enter",
        "enter",
        "enter",
      ]}
      timeout={3000}
    >
      {
        <img
          src="https://cdn.7tv.app/emote/6278fc17bbb6469e8839112e/4x"
          width="100%"
          height="100%"
          className="position-absolute"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 10 }}
        />
      }
    </EasterEgg>
  );
};
