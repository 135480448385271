import React, { useEffect } from "react";
import { useHelperContext } from "./use-helper-context";
import { Link } from "react-router-relative-link-5";

interface HeaderProps {
  title: string;
  description: string;
}

export default (props: HeaderProps) => {
  const { title, description } = props;
  const { smartBar, streamerSelf, deleteStreamerToken } = useHelperContext();

  const handleLogout = () => {
    deleteStreamerToken();
  };

  return (
    <>
      {smartBar && (
        <div
          dangerouslySetInnerHTML={{
            __html: smartBar?.text,
          }}
        ></div>
      )}
      <div className="app-cover">&nbsp;</div>
      <div className="app-header p-20">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-auto col-lg">
              <Link to="/">
                <img
                  alt="دونو"
                  className="app-logo"
                  src={require("./images/logo.svg")}
                />
              </Link>
            </div>
            <div className="col-lg-auto d-none d-lg-flex">
              <nav className="navbar navbar-expand-lg navbar-dark">
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      {streamerSelf ? (
                        <Link
                          to="/panel"
                          className="nav-link text-white px-15 py-half d-flex align-items-center position-relative"
                          style={{
                            background: "rgba(255, 255, 255, 0.3)",
                            borderRadius: "25px",
                          }}
                        >
                          <img
                            src={streamerSelf?.twitch?.profileUrl}
                            width={30}
                            height={30}
                            className="rounded-circle ml-half"
                          />
                          {streamerSelf?.twitch?.username}
                        </Link>
                      ) : (
                        <Link to="/panel" className="nav-link text-white">
                          پنل استریمر
                        </Link>
                      )}
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/privacy-policy"
                        className="nav-link text-white"
                      >
                        حریم خصوصی
                      </Link>
                    </li>

                    <li className="nav-item">
                      <a
                        className="nav-link text-white"
                        href="https://discord.gg/z5mVS4NhAj"
                      >
                        دیسکورد
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link text-white"
                        href="mailto:help@dono.gg"
                      >
                        تماس با ما
                      </a>
                    </li>
                    {streamerSelf && (
                      <li className="nav-item">
                        <a
                          className="nav-link text-white"
                          onClick={handleLogout}
                        >
                          خروج
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="app-hero text-white text-center">
        <div className="container mt-40 pb-half">
          <h1 className="text-white">{title}</h1>
          <p className="mx-auto px-30">{description}</p>
        </div>
      </div>
    </>
  );
};
