import React, { useEffect, useState } from "react";
import EasterEgg from "./EasterEgg";
import Header from "./Header";
import Layout from "./Layout";
import QA from "./QA";
import config from "./Config";
import IBuyer from "./Buyer.model";
import useSearchResults from "./hooks/useSearchResults";
import SuggestionDropdown from "./SuggestionDropdown";
import notification from "antd";
import { useRouteMatch } from "react-router";
let searchTimeout: any;

export default ({ parentOToken }: { parentOToken: string }) => {
  let { path } = useRouteMatch();
  const [streamer, setStreamer] = useState<IBuyer>({
    name: "",
    confirmed: false,
    logo: "",
  });
  const searchResults = useSearchResults(
    streamer,
    "streamerOnly",
    parentOToken
  );

  const handleBuyerUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setStreamer({
      name: value,
      confirmed: false,
      logo: "",
    });
  };

  const handleSearchResult = (result: any) => {
    setStreamer({
      name: result?.channel?.broadcaster_login,
      confirmed: true,
      logo: result?.user?.profile_image_url,
    });
  };

  const handleRedirectToStreamer = () => {
    if (streamer?.confirmed) {
      window.location.href = `https://sub.dono.gg/${streamer.name}`;
    }
  };

  return (
    <>
      <EasterEgg />
      <Header
        title="سابسکرایب آنی توییچ"
        description="بدون نیاز به ارسال پسوورد توییچ، به صورت قانونی و آنی استریمر مورد علاقه خود را سابسکرایب کنید، برای شروع نام استریمر مورد نظر خود (اعم از فارسی/غیرفارسی) را وارد کنید."
      />
      <Layout className="mt-30">
        <div className="px-30 px-lg-60 pt-40">
          <b>نام استریمر مورد نظر خود را وارد کنید:</b>
          <div className="row gx-3 mt-20">
            <div className="col">
              <div className="position-relative">
                <input
                  className={`form-control`}
                  placeholder="یوزرنیم توییچ استریمر مورد نظر"
                  value={streamer?.name}
                  onChange={handleBuyerUsername}
                />
                <SuggestionDropdown
                  searchResults={searchResults}
                  handleSearchResult={handleSearchResult}
                  buyer={streamer}
                />
              </div>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-purple px-30 h-100"
                onClick={handleRedirectToStreamer}
                disabled={!streamer?.confirmed}
              >
                بزن بریم
              </button>
            </div>
          </div>
        </div>
      </Layout>
      <Layout className="mb-40" style={{ marginTop: -20 }}>
        <div className="px-30 px-lg-60 pt-45">
          <QA
            question="سابسکریپشن به چه صورتی انجام می‌شود؟"
            answer="سابسکریپشن خریداری شده به صورت آنی پس از پرداخت به صورت ناشناس یا Anonymous به اکانت شما Gift می‌شود. به همین دلیل عملیات سیستم بدون نیاز به یوزرنیم و پسوورد اکانت شما انجام می‌شود. این روش قانونی بوده و خطری برای اکانت شما ایجاد نمی‌کند."
          />
          <QA
            question="آیا می‌توان با یک اکانت چندین استریمر را سابسکرایب کرد؟"
            answer="بله، هیچ محدودیتی در این روش وجود نداشته و شما می‌توانید تمامی استریمر های دلخواه خود را سابسکرایب کرده و حمایت کنید."
          />
          <QA
            question="هزینه‌ی سابسکریپشن به چه صورت حساب می‌شود؟"
            answer="تمامی بخش های سیستم ساب آنی به صورت اتوماتیک بوده و هزینه آن نیز با الگوریتم خاصی تنظیم شده و به صورت اتوماتیک به روز می ‌شود."
          />
          <QA
            question="من استریمر هستم، چگونه می‌توانم صفحه اختصاصی خود را داشته باشم؟"
            answer={
              <div>
                <span>
                  دایرکتوری سابسکریپشن دونو برای تمامی استریمر ها باز بوده و با
                  افزودن نام خود به انتهای URL می‌توانید لینک مستقیم خود را
                  داشته باشید. مثلا اگر یوزرنیم توییچ شما shroud است لینک به این
                  صورت خواهد بود:
                </span>
                <pre className="px-2 mx-2">sub.dono.gg/shroud</pre>
              </div>
            }
          />
          <QA
            question="چگونه می توانم با پشتیبانی در ارتباط باشم؟"
            answer={
              <div>
                <span className="d-flex">
                  برای ارتباط با ما می‌توانید از آیکن{" "}
                  <div
                    className="rounded-circle p-2 d-flex mx-2"
                    style={{ backgroundColor: "#1972f5" }}
                  >
                    <img
                      src={require("./images/crisp.svg")}
                      width={15}
                      height={15}
                    />
                  </div>{" "}
                  در قسمت پایین راست صفحه استفاده کنید.
                </span>
              </div>
            }
            last={true}
          />
        </div>
      </Layout>
    </>
  );
};
