import React from "react";

interface StreamerHeaderProps {
  title: string;
  followers: number;
  logo: string;
  className: string;
  type: "partner" | "affiliate" | "normal" | undefined | null;
  banner: string;
}

export default ({
  title,
  followers,
  logo,
  className,
  type,
  banner,
}: StreamerHeaderProps) => {
  return (
    <div
      className={`app-streamer-header px-40 pt-30 pb-25 mx-lg-n80 mx-n20 mt-n50 ${className}`}
    >
      <div
        className="row align-items-center position-relative"
        style={{ zIndex: 2 }}
      >
        <div className="col-auto">
          <div className="app-streamer-header-avatar rounded-circle">
            {logo && <img src={logo} className="rounded-circle" />}
          </div>
        </div>
        <div className="col">
          {title && (
            <>
              <h2 className="mb-1 d-flex align-items-center text-capitalize">
                {title}
                {type === "partner" ? (
                  <svg
                    className="mr-1"
                    width="20px"
                    height="20px"
                    version="1.1"
                    viewBox="0 0 20 20"
                    x="0px"
                    y="0px"
                    fill="#9147ff"
                  >
                    <path d="M10 2l6 2 2 6-2 6-6 2-6-2-2-6 2-6 6-2zM8.889 13.636l5.43-5.429-1.415-1.414-4.015 4.015-2.015-2.015-1.414 1.414 3.429 3.43z"></path>
                  </svg>
                ) : null}
              </h2>
              <span>{followers} فالوئر</span>
            </>
          )}
        </div>
      </div>
      <div
        className="app-streamer-header-banner"
        style={{ backgroundImage: `url(${banner})` }}
      />
    </div>
  );
};
