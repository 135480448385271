import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "./scss/bootstrap.scss";
import "./scss/app.scss";
import StreamerSub from "./StreamerSub";
import PaymentResult from "./PaymentResult";
import Home from "./Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import config from "./Config";
import { Panel } from "./Panel";
import { LoginCallback } from "./LoginCallback";
import Privacy from "./Privacy";

export default () => {
  const [oToken, setOToken] = useState<string>("");

  const getOAuthToken = async (): Promise<any> => {
    return fetch(`${config.apiUrl}/auth/client/token`, {
      method: "POST",
    });
  };

  useEffect(() => {
    // Include the Crisp code here, without the <script></script> tags
    // @ts-ignore
    window.$crisp = [];
    // @ts-ignore
    window.CRISP_WEBSITE_ID = "78b13c5f-a2bc-4495-8c63-4c342e35dcde";

    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      // @ts-ignore
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const tokenReq = await getOAuthToken();
      const {
        data: { access_token },
      } = await tokenReq.json();
      setOToken(access_token);
    })();
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/login/callback" component={LoginCallback} />
        <Route path="/panel" component={Panel} />
        <Route path="/privacy-policy" component={Privacy} />
        <Route path="/payment" component={PaymentResult} />
        <Route
          path="/:streamer"
          component={(
            props: JSX.IntrinsicAttributes & { parentOToken: string }
          ) =>
            oToken ? <StreamerSub {...props} parentOToken={oToken} /> : null
          }
        />
        <Route
          path="/"
          component={() => (oToken ? <Home parentOToken={oToken} /> : null)}
        />
      </Switch>
    </Router>
  );
};
