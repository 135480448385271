import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";
import { message } from "antd";
import config from "./Config";
import { useHelperContext } from "./use-helper-context";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const LoginCallback = () => {
  let query = useQuery();
  const code = query.get("code");
  const history = useHistory();
  const { saveStreamerToken } = useHelperContext();

  useEffect(() => {
    (async () => {
      try {
        if (!code) {
          message.error("مشکلی پیش آمد.");
          return history.push("/panel");
        }
        message.info("در حال احراز هویت ..");
        const req = await fetch(`${config.apiUrl}/auth/twitch/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ code }),
        });
        const { data } = await req.json();
        if (data) {
          message.success("با موفقیت وارد شدید.");
          const { token } = data;
          saveStreamerToken(token);
          history.push("/panel");
        } else {
          message.error("عملیت ناموفق بود، لطفا مجددا تلاش کنید.");
          history.push("/panel");
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  return <></>;
};
