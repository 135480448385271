import { useEffect, useState } from "react";
import config from "../Config";
import IBuyer from "../Buyer.model";
let searchTimeout: any;

export default (
  buyer: IBuyer,
  type?: "streamerOnly" | null,
  oToken?: string
) => {
  const [searchResults, setSearchResults] = useState<Array<object>>([]);

  useEffect(() => {
    (async () => {
      if (buyer && buyer.name.length > 3 && oToken) {
        // prevent multiple requests on each word
        if (searchTimeout !== undefined) clearTimeout(searchTimeout);
        // clear result box on new input
        setSearchResults([]);
        // making a search request unless getting cleared
        searchTimeout = setTimeout(async () => {
          const req = await fetch(
            `${config.apiUrl}/twitch/streamer/${buyer.name}`,
            {
              headers: {
                Authorization: `Bearer ${oToken}`,
                "Client-ID": config.oClientId,
              },
            }
          );
          const { data: channels } = await req.json();
          channels && setSearchResults([channels]);
        }, 250);
      }
    })();
  }, [buyer]);

  return searchResults;
};
