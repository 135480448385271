import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Layout from "./Layout";
import config from "./Config";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

enum UserOrderStatus {
  FAILED = "FAILED",
  PROCESSING = "PROCESSING",
  COMPLETED = "COMPLETED",
  AWAITING_COMPLETED = "AWAITING_COMPLETED",
  CANCELED = "CANCELED",
  PENDING_PAYMENT = "PENDING_PAYMENT",
  REFUNDED = "REFUNDED",
}

type OrderStatusInterface = {
  target: string;
  streamer: string;
  status: UserOrderStatus;
};

export default () => {
  let query = useQuery();
  const status = query.get("success");
  const orderId = query.get("orderId");
  const successful = status === "true";
  const [orderStatus, setOrderStatus] = useState<OrderStatusInterface>();
  const [to, setTo] = useState();

  const getOrderStatus = async () => {
    const req = await fetch(`${config.apiUrl}/orders/${orderId}/status`);
    const { data } = await req.json();

    setOrderStatus(data);
  };

  useEffect(() => {
    (async () => {
      try {
        if (!successful) {
          return;
        }

        if (!orderId) {
          return;
        }

        //await getOrderStatus();

        const refreshOrderStatus = setInterval(() => {
          getOrderStatus();
        }, 5000);

        setTo(refreshOrderStatus);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [orderId]);

  useEffect(() => {
    const openedWindow = window?.opener;
    if (!openedWindow) {
      return;
    }
    console.log(openedWindow);

    openedWindow?.postMessage(
      {
        successful,
        orderId,
      },
      "*"
    );
  }, []);

  useEffect(() => {
    if (!orderStatus) {
      return;
    }

    const { status } = orderStatus;

    if (status === "PROCESSING" || status === "FAILED") {
      return;
    }

    clearInterval(to);
  }, [orderStatus]);

  return (
    <>
      <Header
        title={successful ? "با تشکر از خرید شما" : "مشکل در تکمیل پرداخت"}
        description="بدون نیاز به ارسال پسوورد توییچ، به صورت قانونی و آنی استریمر مورد علاقه خود را سابسکرایب کنید."
      />
      <Layout className="mt-55">
        <div className="px-40 px-lg-60 pt-40">
          {successful ? (
            <>
              {orderStatus ? (
                <>
                  {orderStatus?.status === "PROCESSING" && (
                    <div className="alert alert-info">
                      <svg
                        className="spinner position-absolute"
                        style={{ marginRight: "-40px", marginTop: "3px" }}
                        width="30px"
                        height="30px"
                        viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="path"
                          fill="none"
                          stroke-width="6"
                          stroke-linecap="round"
                          cx="33"
                          cy="33"
                          r="30"
                        ></circle>
                      </svg>
                      سابسکریپشن شما در حال پردازش است..
                    </div>
                  )}
                  {orderStatus?.status === "PENDING_PAYMENT" && (
                    <div className="alert alert-info">
                      <svg
                        className="spinner position-absolute"
                        style={{ marginRight: "-40px", marginTop: "3px" }}
                        width="30px"
                        height="30px"
                        viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="path"
                          fill="none"
                          stroke-width="6"
                          stroke-linecap="round"
                          cx="33"
                          cy="33"
                          r="30"
                        ></circle>
                      </svg>
                      در حال تایید پرداخت شما هستیم..
                    </div>
                  )}
                  {orderStatus?.status === "FAILED" && (
                    <div
                      className="alert alert-info"
                      style={{
                        background: "#fff9ef",
                        borderColor: "#ffeecf",
                        color: "#e08e48",
                      }}
                    >
                      <svg
                        className="spinner position-absolute"
                        style={{ marginRight: "-40px", marginTop: "3px" }}
                        width="30px"
                        height="30px"
                        viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="path"
                          fill="none"
                          stroke-width="6"
                          stroke-linecap="round"
                          cx="33"
                          cy="33"
                          r="30"
                        ></circle>
                      </svg>
                      زمان انجام سابسکریپشن شما ممکن است کمی طولانی تر از حد
                      عادی باشد، لطفا منتظر بمانید. کد سفارش شما: {orderId}
                    </div>
                  )}
                  {orderStatus?.status === "CANCELED" && (
                    <div
                      className="alert alert-info"
                      style={{
                        background: "#fff9ef",
                        borderColor: "#ffeecf",
                        color: "#e08e48",
                      }}
                    >
                      <svg
                        className="spinner position-absolute"
                        style={{ marginRight: "-40px", marginTop: "3px" }}
                        width="30px"
                        height="30px"
                        viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="path"
                          fill="none"
                          stroke-width="6"
                          stroke-linecap="round"
                          cx="33"
                          cy="33"
                          r="30"
                        ></circle>
                      </svg>
                      متاسفانه قادر به تایید پرداخت شما نیستیم. در صورتی که وجهی
                      از حساب شما کسر شده تا ساعات آینده به حساب شما بازخواهد
                      گشت.
                    </div>
                  )}
                  {orderStatus?.status === "AWAITING_COMPLETED" && (
                    <div className="alert alert-info">
                      <svg
                        className="spinner position-absolute"
                        style={{ marginRight: "-40px", marginTop: "3px" }}
                        width="30px"
                        height="30px"
                        viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="path"
                          fill="none"
                          stroke-width="6"
                          stroke-linecap="round"
                          cx="33"
                          cy="33"
                          r="30"
                        ></circle>
                      </svg>
                      پرداخت شما انجام شده و در انتظار تایید توسط توییچ است.
                    </div>
                  )}
                  {orderStatus?.status === "COMPLETED" && (
                    <div className="alert alert-success">
                      سابسکریپشن شما با موفقیت انجام شد.
                    </div>
                  )}
                </>
              ) : (
                <div className="alert alert-info">
                  <svg
                    className="spinner position-absolute"
                    style={{ marginRight: "-40px", marginTop: "3px" }}
                    width="30px"
                    height="30px"
                    viewBox="0 0 66 66"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="path"
                      fill="none"
                      stroke-width="6"
                      stroke-linecap="round"
                      cx="33"
                      cy="33"
                      r="30"
                    ></circle>
                  </svg>
                  در حال اتصال به سیستم ..
                </div>
              )}
            </>
          ) : (
            <div className="alert alert-danger">
              {" "}
              در فرایند پرداخت مشکلی پیش آمد، در صورتی که مبلغی از حساب شما کسر
              شده حداکثر تا ۴۸ ساعت آینده به حساب شما بازخواهد گشت.
            </div>
          )}
          <a href="https://dono.gg/sub" className="btn btn-purple mt-15 px-30">
            بازگشت
          </a>
        </div>
      </Layout>
    </>
  );
};
