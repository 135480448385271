import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import config from "./Config";
import notification from "./helpers/notifications";
import Indicator from "./Indicator";
import SuggestionDropdown from "./SuggestionDropdown";
import useSearchResults from "./hooks/useSearchResults";
import IBuyer from "./Buyer.model";
import filterPrice from "./helpers/filterPrice";
import { CheckoutDetails } from "./CheckoutDetails";
import { GiftPage } from "./GiftPage";

interface TierPaymentProps {
  streamer: string;
  price: any[];
  oToken: string | undefined;
  giftMode?: boolean;
}

const ENGLISH_WORDS =
  "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_-.";

function isStringEnglish(text: string) {
  for (const character of text) {
    if (!ENGLISH_WORDS.includes(character)) {
      return false;
    }
  }

  return true;
}

let searchTimeout: any;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default ({ streamer, price, oToken, giftMode }: TierPaymentProps) => {
  let { tier: tierId } = useParams();
  let { quantity } = useParams();
  const tier: number = tierId === "tier-1" ? 1 : tierId === "tier-2" ? 2 : 3;
  const [buyer, setBuyer] = useState<IBuyer>({
    name: "",
    confirmed: false,
    logo: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = useState();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>();
  const [message, setMessage] = useState();

  let searchResults = useSearchResults(buyer, null, oToken);
  let query = useQuery();
  quantity = +quantity;

  const handleBuyerUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setBuyer({
      name: value,
      confirmed: false,
      logo: "",
    });
  };

  const handleUx = () => {
    if (buyer?.confirmed) {
      setBuyer({ ...buyer, confirmed: false, logo: "" });
    }
  };

  const handleSearchResult = (result: any) => {
    const { display_name, profile_image_url } = result?.user;
    if (display_name.toLowerCase() === streamer.toLocaleLowerCase()) {
      return notification(
        "error",
        "خطا",
        "شما نمی‌توانید خودتان را سابسکرایب کنید."
      );
    }
    setBuyer({ name: display_name, confirmed: true, logo: profile_image_url });
  };

  const handlePayment = async () => {
    try {
      const { id } = filterPrice(tier, price);
      setLoading(true);

      let streamerEnglishName = streamer;

      if (!isStringEnglish(streamer)) {
        const { pathname } = new URL(window.location.href);
        const [, streamerName] = pathname.split("/");

        if (!isStringEnglish(streamerName)) {
          // TODO: Handle this error like a normal human being
          throw new Error("INVALID_NAME");
        }

        streamerEnglishName = streamerName.toLowerCase().trim();
      }

      const req: any = await fetch(
        `${config.apiUrl}/subscriptions/${id}/create`,
        {
          method: "POST",
          redirect: "follow",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentMethodId: selectedPaymentMethod?.id,
            target: buyer?.name,
            streamer: streamerEnglishName,
            callbackUrl: "https://sub.dono.gg/payment",
            isCommunityGift: quantity ? true : undefined,
            communityGiftQuantity: quantity || undefined,
            message: message || undefined,
          }),
        }
      );
      const {
        statusCode,
        data,
        message: errorMessage,
        ...props
      } = await req.json();
      if (!data) {
        return notification("error", "خطا", errorMessage);
      }
      window.location.href = data.url;
    } catch (e) {
      console.error(e);
      notification("error", "خطا", "مشکلی پیش آمد.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const req = await fetch(`${config.apiUrl}/paymentmethods/active`);
        const { data } = await req.json();
        setPaymentMethods(data);
        const [firstPaymentMethod] = data;
        setSelectedPaymentMethod(firstPaymentMethod);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  return (
    <div className="app-tiers px-40 px-lg-60">
      <div className="app-tier position-relative" id={tierId}>
        <Link
          to={`/${streamer?.toLowerCase()}`}
          className="app-tier-back text-decoration-none"
        >
          <img src={require("./images/right-arrow.svg")} className="ml-10" />
          بازگشت
        </Link>
        <div className="row">
          <div className="col">
            <div className="app-tier-payment-form">
              <h2>
                {giftMode && "هدیه "}سابسکریپشن{" "}
                <span className="d-inline-flex align-items-center justify-content-center px-15 mr-1 mb-30">
                  Tier {tier}
                </span>
              </h2>
              <label>
                {buyer?.confirmed ? (
                  `سابسکریپشن بعد از تکمیل پرداخت در کمتر از ۵ ثانیه به ${buyer?.name} گیفت می‌شود و توسط DonoBot در چت اعلام می‌شود.`
                ) : giftMode ? (
                  <span>
                    سابسکریپشن به صورت اتفاقی به یکی از یوزر های چت ارسال خواهد
                    شد، برای اینکه استریمر بتواند نام شما را به عنوان اهدا کننده
                    در پنل مشاهده کند نام خود را در کادر زیر وارد کنید.
                    <br />
                    <span style={{ color: "red" }}>
                      لطفا دقت داشته باشید در کادر زیر باید یوزرنیم خودتان (اهدا
                      کننده) را وارد کنید.
                      <Link
                        to="../"
                        className="text-decoration-none text-red"
                        style={{ color: "red" }}
                      >
                        در صورتی که قصد دارید به شخص خاصی گیفت را ارسال کنید
                        اینجا کلیک کنید.
                      </Link>
                    </span>
                  </span>
                ) : (
                  "یوزرنیم توییچ اکانتی که سابسکریپشن به آن ارسال می‌شود را وارد کنید."
                )}
              </label>
              <div className="position-relative mb-3">
                <div
                  className="d-flex mt-20 align-items-center"
                  style={{
                    background: buyer?.confirmed ? "#f2f3f9" : undefined,
                    borderRadius: buyer?.confirmed ? "6px" : undefined,
                    padding: buyer?.confirmed ? "6px 12px" : undefined,
                  }}
                >
                  {buyer?.logo && (
                    <img
                      src={buyer.logo}
                      width="48"
                      height="48"
                      className="rounded-circle ml-15"
                    />
                  )}
                  <input
                    className={`form-control ${
                      searchResults?.length > 0 && !buyer?.confirmed
                        ? "form-control-focus"
                        : buyer?.confirmed
                        ? "selected text-capitalize rounded-md text-bold"
                        : ""
                    }`}
                    placeholder="یوزرنیم توییچ"
                    value={buyer?.name}
                    onChange={handleBuyerUsername}
                    onClick={handleUx}
                  />
                </div>
                <SuggestionDropdown
                  searchResults={searchResults}
                  handleSearchResult={handleSearchResult}
                  buyer={buyer}
                />
              </div>

              {buyer?.confirmed && (
                <div>
                  <input
                    className={`form-control`}
                    placeholder="متن ساب (اختیاری)"
                    value={message}
                    onChange={(e) => setMessage(e?.target?.value)}
                  />
                </div>
              )}

              <div className="d-flex align-items-center mt-20">
                {paymentMethods &&
                  paymentMethods?.map(({ id, name, isActive }: any) => (
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id={`${name}-radio`}
                        value={name}
                        onClick={() => setSelectedPaymentMethod({ id, name })}
                        checked={selectedPaymentMethod?.name === name}
                      />
                      <label
                        className="form-check-label d-flex align-items-center"
                        htmlFor={`${name}-radio`}
                      >
                        {name === "zarinpal" && (
                          <img
                            height={17}
                            src={require("./images/zarinpal-logo.svg")}
                          />
                        )}
                        {name === "idpay" && (
                          <img
                            height={30}
                            src={require("./images/idpay-logo.svg")}
                          />
                        )}
                        {name !== "idpay" && name !== "zarinpal" && name}
                      </label>
                    </div>
                  ))}
              </div>

              <div className="d-flex align-items-center mt-25">
                <button
                  className="btn btn-purple px-30"
                  disabled={!buyer?.confirmed || loading}
                  onClick={handlePayment}
                >
                  پرداخت
                </button>
                {loading && <Indicator />}
              </div>
            </div>
          </div>
          <div className="col-auto mr-50">
            <CheckoutDetails
              tier={tier}
              price={price}
              streamer={streamer}
              quantity={quantity || ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
